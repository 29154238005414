export const landingBg_3_lg = "/Landing/landingbg_3_lg.jpg";
export const landingBg_4_lg = "/Landing/landingbg_4_lg.jpg";
export const landingBg_5_lg = "/Landing/landingbg_5_lg.jpg";
export const landingBg_7_lg = "/Landing/landingbg_7_lg.jpg";

export const landingBg_lg = [
  landingBg_3_lg,
  landingBg_4_lg,
  landingBg_5_lg,
  landingBg_7_lg,
];
