import { useState, useEffect } from "react";
import { SCREEN_ORIENTATION } from "../../../common/constants";

const checkOrientation = () => {
  if (window.innerWidth > window.innerHeight) {
    return SCREEN_ORIENTATION.LANDSCAPE;
  } else {
    return SCREEN_ORIENTATION.PORTRAIT;
  }
};

export const useScreenOrientation = () => {
  const [orientation, setOrientation] = useState(checkOrientation());

  const updateOrientation = () => {
    setOrientation(checkOrientation());
  };

  useEffect(() => {
    window.addEventListener("resize", updateOrientation);
    return () => {
      window.removeEventListener("resize", updateOrientation);
    };
  }, []);

  return orientation;
};
