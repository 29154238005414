const coda = "/Clients/coda.png";
const electricIsland = "/Clients/electricisland.png";
const loveChild = "/Clients/lovechild.png";
const mrWolf = "/Clients/mrwolf.png";
const nomad = "/Clients/nomad.png";
const soluna = "/Clients/soluna.png";
const theHartly = "/Clients/thehartly.png";
const ultraViolet = "/Clients/ultraviolet.png";
const wiggleRoom = "/Clients/wiggleroom.png";

const clientsTitleText = "Clients/clients-short.png";

export const clientImages = {
  coda: {
    title: "Coda",
    logo: coda,
    images: [
      "/Clients/coda/image1.jpeg",
      "/Clients/coda/image2.jpeg",
      "/Clients/coda/image3.jpeg",
      "/Clients/coda/image4.jpeg",
      "/Clients/coda/image5.jpeg",
      "/Clients/coda/image6.jpeg",
      "/Clients/coda/image7.jpeg",
    ],
  },
  soluna: {
    title: "Soluna",
    logo: soluna,
    images: [
      "/Clients/soluna/image1.jpeg",
      "/Clients/soluna/image2.jpeg",
      "/Clients/soluna/image3.jpeg",
      "/Clients/soluna/image4.jpeg",
      "/Clients/soluna/image5.jpeg",
      "/Clients/soluna/image6.jpeg",
      "/Clients/soluna/image7.jpeg",
      "/Clients/soluna/image8.jpeg",
    ],
  },
  electricIsland: {
    title: "Electric Island",
    logo: electricIsland,
    images: [],
  },
  loveChild: {
    title: "Love Child",
    logo: loveChild,
    images: [],
  },
  mrWolf: {
    title: "Mr.Wolf",
    logo: mrWolf,
    images: [
      "/Clients/mister_wolf/image1.jpeg",
      "/Clients/mister_wolf/image2.jpeg",
    ],
  },
  wiggleRoom: {
    title: "Wiggle Room",
    logo: wiggleRoom,
    images: [],
  },
  theHartly: {
    title: "The Hartly",
    logo: theHartly,
    images: [],
  },

  ultraViolet: {
    title: "Ultraviolet",
    logo: ultraViolet,
    images: ["/Clients/ultraviolet/image1.jpeg"],
  },
  nomad: {
    title: "Nomad",
    logo: nomad,
    images: [],
  },
};

export const clientLayoutImages = {
  clientsTitleText,
};
