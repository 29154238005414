import React, { useState, createContext, useContext, ReactNode } from "react";
import ImageModal from "../common/components/ImageModal";
import { IKContext } from "imagekitio-react";

interface Props {
  children?: ReactNode;
}
const urlEndpoint = "https://ik.imagekit.io/pwdev/Disdecor";

export const Context = createContext<any>(undefined);

export const ContextProvider = ({ children, ...props }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<string>("");
  const [modalData, setModalData] = useState<any[]>([]);

  const clearModalData = () => {
    setActiveItem("");
    setModalData([]);
  };

  const value = {
    showModal,
    setShowModal,
    modalData,
    setModalData,
    activeItem,
    setActiveItem,
    clearModalData,
  };

  return (
    <IKContext urlEndpoint={urlEndpoint}>
      <Context.Provider value={value}>
        {showModal && <ImageModal />}
        {!showModal && children}
      </Context.Provider>
    </IKContext>
  );
};

export const useStateContext = () => useContext(Context);
