import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { isMobileOnly } from "react-device-detect";

import { IKImage } from "imagekitio-react";

import { aboutImages } from "../../utils/ImageLoaders/About";
import { disdecorLogo } from "../../utils/ImageLoaders/Common";

import "./style.css";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container fluid id="about-section" className="nav-margin p-0">
      <Container
        fluid
        id="about-stack"
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <div id="title-container" className="pt-2">
          <IKImage
            path={aboutImages.aboutusTitle}
            className="col-11 col-sm-10 col-md-7 col-lg-7 col-xl-4 mx-auto"
            width="300"
            loading="lazy"
          />
        </div>

        <div
          id="about-description-container"
          className="glass-card d-flex flex-column justify-content-center align-items-center p-4"
        >
          <p
            id="about-description"
            className={`m-0 fw-light text-white ${
              isMobileOnly ? "fs-6" : "fs-5"
            } `}
          >
            We specialize in creating unique and memorable atmospheres for
            nightclubs and other entertainment venues. Our team of talented
            designers and craftsmen work together to bring your vision to life,
            whether you're looking for a sleek and modern aesthetic or something
            bold and edgy.
          </p>
          <IKImage
            id="about-logo"
            path={disdecorLogo}
            height="100"
            className="m-2"
            loading="lazy"
            lqip={{ active: true }}
          />

          <p
            id="about-description"
            className={`fw-light text-white ${isMobileOnly ? "fs-6" : "fs-5"} `}
          >
            We use high-quality materials and the latest technology to create
            custom installations, lighting, and floral arrangements that will
            set your club apart from the rest. With years of experience and a
            passion for creating unforgettable experiences, we're excited to
            work with you to transform your space into a one-of-a-kind
            destination
          </p>
        </div>
      </Container>
    </Container>
  );
}

export default About;
