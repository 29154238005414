import React, { useEffect } from "react";
import { Row, Col, Container, Carousel } from "react-bootstrap";
import { IKImage } from "imagekitio-react";

import serviceData from "./ServiceList";
import { isMobileOnly } from "react-device-detect";
import serviceImages from "../../utils/ImageLoaders/Services";

import "./style.css";
import { useStateContext } from "../../context";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setShowModal, setModalData, setActiveItem } = useStateContext();

  return (
    <Container
      id="services-section"
      className="p-2 mb-5 p-lg-0 d-flex flex-column justify-content-evenly align-items-center nav-margin"
      fluid
    >
      <Row className="pt-1">
        <IKImage
          path={serviceImages.servicesTitle}
          className="col-11 col-sm-10 col-md-8 col-lg-7 col-xl-4 mx-auto"
          width="300"
        />
      </Row>
      <Row className="mx-0 px-1 d-flex flex-column justify-content-center align-items-center">
        {serviceData.services.map((service) => {
          return (
            <Col
              key={service.title}
              xs="12"
              lg="10"
              className="service-card glass-card p-4 mb-3"
            >
              <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                <div className="w-100 flex-grow-1">
                  <Carousel indicators={false} pause={"hover"} fade>
                    {service.images.map((path, i) => {
                      return (
                        <Carousel.Item key={path + i}>
                          <IKImage
                            className="service-card-image clickable h-100 w-100"
                            path={path}
                            lqip={{ active: true }}
                            loading="lazy"
                            width="500"
                            height="400"
                            onClick={async () => {
                              await setModalData([...service.images]);
                              await setActiveItem(path);
                              setShowModal(true);
                            }}
                          />
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                </div>
                <div className="w-100 flex-grow-1 p-md-3">
                  <h2 className="text-white fw-light text-start mt-3 mt-md-0">
                    {service.title}
                  </h2>
                  <p
                    className={`fw-light text-white ${
                      isMobileOnly ? "fs-6" : "fs-5"
                    } text-start service-card-text`}
                  >
                    {service.description}
                  </p>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default Services;
