import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { clientLayoutImages } from "../../utils/ImageLoaders/Clients";
import { isMobileOnly, isMobile } from "react-device-detect";
import { IKImage } from "imagekitio-react";

import { useStateContext } from "../../context";

import { clientImages } from "../../utils/ImageLoaders/Clients";

import "./style.css";

function Clients() {
  const { setShowModal, setModalData, setActiveItem } = useStateContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="clients-section" className="nav-margin p-1">
      <Container
        fluid
        id="clients-section-inner"
        className="d-flex flex-column justify-content-center align-items-center p-2"
      >
        <Container fluid className="clients-title-container">
          <IKImage
            path={clientLayoutImages.clientsTitleText}
            className="col-6 col-sm-5 col-md-4 col-lg-4 col-xl-2 mx-auto"
            width="300"
            loading="lazy"
          />
        </Container>

        <Container
          id="clients-description-container"
          className="glass-card d-flex flex-row flex-wrap justify-content-center align-items-center p-4 mt-1"
        >
          <p
            className={`fw-light text-white ${
              isMobileOnly ? "fs-6" : "fs-5"
            } mb-0`}
          >
            We're committed to understanding their needs, exceeding their
            expectations, and delivering exceptional value every step of the
            way. Our clients inspire us to innovate, to push the boundaries of
            what's possible, and to constantly strive for excellence. We're
            proud to partner with some of the most dynamic and forward-thinking
            venues out there.
          </p>
          <div className="d-flex flex-row flex-wrap justify-content-center align-items-stretch p-0">
            {Object.values(clientImages).map((client, i) => {
              return (
                <div
                  key={client.title + i}
                  className={`${
                    client.images.length > 0 && "clickable hover-scale"
                  } client-logo p-2 mx-0 mx-sm-3 d-flex flex-column justify-content-center align-items-center`}
                  onClick={async () => {
                    if (client.images.length > 0) {
                      await setActiveItem(client.images[0]);
                      await setModalData([...client.images]);
                      setShowModal(true);
                    }
                  }}
                >
                  <IKImage
                    path={client.logo}
                    alt={client.title}
                    className={`client-logo_image`}
                    width="150"
                    lqip={{ active: true }}
                    loading="lazy"
                  />
                </div>
              );
            })}
          </div>
        </Container>
        <small className="text-secondary mt-3">
          {isMobile ? "Tap" : "Click"} on a client to view our work
        </small>
      </Container>
    </div>
  );
}

export default Clients;
