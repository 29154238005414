import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Telephone, Envelope, Instagram } from "react-bootstrap-icons";
import { IKImage } from "imagekitio-react";

import {
  disdecorLogo,
  disdecorTitle,
} from "../../../utils/ImageLoaders/Common";

import "./style.css";

function index() {
  return (
    <div
      id="footer"
      className="nav-bar py-2 mt-5 glass border-0 border-top border-dark shadow"
    >
      <Container>
        <Row className="g-0 p-0 m-0">
          <Col
            xs={12}
            lg={4}
            className="p-2 d-flex flex-column justify-content-center align-items-center"
          >
            <IKImage
              id="footer-logo"
              path={disdecorLogo}
              className="h-full"
              loading="lazy"
              lqip={{ active: true }}
              height="100"
            ></IKImage>
            <IKImage
              id="footer-logo-title"
              path={disdecorTitle}
              loading="lazy"
              width="200"
              lqip={{ active: true }}
            ></IKImage>
            <span className="fw-lighter fs-5 text-light">Toronto, Ontario</span>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={4}
            className="p-2 fw-lighter text-light d-flex flex-column align-items-center"
          >
            <span className="fs-3">Contact</span>
            <a href="tel:416-904-1259" className="text-decoration-none">
              <span className="fs-5 text-light">
                <Telephone className="mx-2 text-primary" />
                416-904-1259
              </span>
            </a>
            <a
              href="mailto:matt.disalvia@gmail.com"
              className="text-decoration-none"
            >
              <span className="fs-5 text-light">
                <Envelope className="mx-2 text-primary" />
                matt.disalvia@gmail.com
              </span>
            </a>
            <a
              href="https://www.instagram.com/dis.decor_/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none"
            >
              <span className="fs-5 text-light">
                <Instagram className="mx-2 text-primary" />
                dis.decor_
              </span>
            </a>
          </Col>
          <Col
            className="p-2 fw-lighter text-light d-flex flex-column align-items-center"
            xs={12}
            md={6}
            lg={4}
          >
            <span className="fs-3">Servicing the GTA</span>
            <span className="fs-5">Toronto</span>
            <span className="fs-5">North York</span>
            <span className="fs-5">Scarborough</span>
            <span className="fs-5">Etobicoke</span>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default index;
