import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { disdecorLogo } from "../../../utils/ImageLoaders/Common";
import { Link, useLocation } from "react-router-dom";
import { IKImage } from "imagekitio-react";

import {
  instagram_logo,
  disdecorTitle,
} from "../../../utils/ImageLoaders/Common";

import "./style.css";

const StickyNavbar = () => {
  const location = useLocation();

  const [expanded, setExpanded] = useState(false);

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 350);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    setIsSmallScreen(window.innerWidth < 350);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Navbar
      className="nav-bar border-bottom border-dark"
      variant="dark"
      expand="lg"
      expanded={expanded}
      fixed="top"
    >
      <Container fluid className="d-flex flex-row">
        <div className="d-flex flex-row justify-content-center align-items-center">
          <Navbar.Brand
            as={Link}
            to="/"
            href="#intro-section"
            className="flex-grow-1 d-flex flex-row align-items-center justify-content-between"
          >
            {!isSmallScreen && (
              <IKImage
                path={disdecorLogo}
                height="50"
                alt="Disdecor logo"
                lqip={{ active: true }}
                loading="lazy"
              ></IKImage>
            )}
            <IKImage
              id="intro-title"
              path={disdecorTitle}
              loading="lazy"
              height="30"
            ></IKImage>
          </Navbar.Brand>
          <a
            href="https://www.instagram.com/dis.decor_/"
            target="_blank"
            rel="noopener noreferrer"
            className="d-block d-lg-none"
          >
            <IKImage
              path={instagram_logo}
              className="instagram-logo px-3 clickable"
              alt="Disdecor Instagram Button"
              lqip={{ active: true }}
              loading="lazy"
              height="30"
            />
          </a>
        </div>
        <Navbar.Toggle
          onClick={() => setExpanded(expanded ? false : "expanded")}
          aria-controls="basic-navbar-nav"
        />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="d-flex flex-col flex-lg-row gap-0 gap-lg-4">
            <Nav.Link
              as={Link}
              to="/"
              className={`browser-link ${
                location.pathname === "/" ? "active" : "inactive"
              }`}
              onClick={() => {
                setExpanded(false);
              }}
            >
              Home
            </Nav.Link>

            <Nav.Link
              as={Link}
              to="/About"
              className={`browser-link ${
                location.pathname === "/About" ? "active" : "inactive"
              }`}
              onClick={() => {
                setExpanded(false);
              }}
            >
              About
            </Nav.Link>

            <Nav.Link
              as={Link}
              to="/Clients"
              className={`browser-link ${
                location.pathname === "/Clients" ? "active" : "inactive"
              }`}
              onClick={() => {
                setExpanded(false);
              }}
            >
              Clients
            </Nav.Link>

            <Nav.Link
              as={Link}
              to="/Services"
              className={`browser-link ${
                location.pathname === "/Services" ? "active" : "inactive"
              }`}
              onClick={() => {
                setExpanded(false);
              }}
            >
              Services
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <a
          href="https://www.instagram.com/dis.decor_/"
          target="_blank"
          rel="noopener noreferrer"
          className="d-none d-lg-block"
        >
          <IKImage
            path={instagram_logo}
            className="instagram-logo px-3 clickable"
            alt="Disdecor Instagram Button"
            height="30"
            loading="lazy"
            lqip={{ active: true }}
          />
        </a>
      </Container>
    </Navbar>
  );
};

export default StickyNavbar;
