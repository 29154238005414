const custom_main = "/Services/custy.jpg";
const floral_main = "/Services/floral.jpeg";
const lighting_main = "/Services/lighting.jpeg";
const lasers = "/Services/lasers_1.png";
const servicesTitle = "/Services/services-title.png";

const serviceImages = {
  custom: [
    custom_main,
    "/Services/custom/custom.jpeg",
    "/Services/custom/custom1.jpeg",
    "/Services/custom/custom2.jpeg",
    "/Services/custom/custom3.jpeg",
    "/Services/custom/custom4.jpeg",
    "/Services/custom/custom5.jpeg",
    "/Services/custom/custom6.jpeg",
    "/Services/custom/custom7.jpeg",
  ],
  drapery: [
    "/Services/drapery/image1.jpeg",
    "/Services/drapery/image2.jpeg",
    "/Services/drapery/image3.jpeg",
    "/Services/drapery/image4.jpeg",
  ],
  floral: [
    floral_main,
    "/Services/floral/image1.jpeg",
    "/Services/floral/image2.jpeg",
    "/Services/floral/image3.jpeg",
    "/Services/floral/image4.jpeg",
    "/Services/floral/image5.jpeg",
    "/Services/floral/image6.jpeg",
  ],
  lighting: [
    lighting_main,
    "/Services/lighting/image1.jpeg",
    "/Services/lighting/image2.jpeg",
  ],
  seasonal: [
    "/Services/seasonal/seasonal.jpeg",
    "/Services/seasonal/image1.jpeg",
    "/Services/seasonal/image2.jpeg",
    "/Services/seasonal/image3.jpeg",
    "/Services/seasonal/image4.jpeg",
    "/Services/seasonal/image5.jpeg",
    "/Services/seasonal/image6.jpeg",
    "/Services/seasonal/image7.jpeg",
  ],
  lasers,
  servicesTitle,
};

export default serviceImages;
