import React from "react";
import { Container, Carousel, Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { IKImage } from "imagekitio-react";

import NextIcon from "../../common/components/CarouselControls/NextIcon";
import PrevIcon from "../../common/components/CarouselControls/PrevIcon";
import recentDesigns from "../../utils/ImageLoaders/RecentDesigns";
import { useStateContext } from "../../context";

import "./style.css";

function CallToAction() {
  const { setShowModal, setModalData, setActiveItem } = useStateContext();

  return (
    <div
      className={`d-flex flex-column justify-content-start align-items-center bg`}
    >
      <Container className="call-to-action d-flex flex-column justify-content-center align-items-stretch">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h2 className="display-3 text-uppercase text-light fw-lighter m-0">
            Recent Designs
          </h2>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Carousel
            pause={false}
            indicators={true}
            interval={null}
            slide
            nextIcon={<NextIcon />}
            prevIcon={<PrevIcon />}
            className="cta_carousel"
          >
            {recentDesigns.map((value, index) => {
              return (
                <Carousel.Item
                  className="px-4 px-md-5 px-lg-0 carousel__item"
                  key={value + index}
                >
                  <Row>
                    <Col xs={11} className="mx-auto rounded-4">
                      <IKImage
                        path={value}
                        className="carousel__image rounded-4 border border-dark"
                        onClick={async () => {
                          await setModalData([...recentDesigns]);
                          await setActiveItem(value);
                        setShowModal(true);
                        }}
                        lqip={{ active: true }}
                        width="900"
                      />
                    </Col>
                  </Row>
                </Carousel.Item>
              );
            })}
          </Carousel>
          <small className="text-secondary">
            {isMobile ? "Tap" : "Click"} image to enlarge
          </small>
        </div>
      </Container>
    </div>
  );
}

export default CallToAction;
