export const disdecorLogo = "/Common/logo.png";
export const instagram_logo = "/Common/instagram-logo.png";
export const disdecorTitle = "/Common/disdecorV2title.png";

const commonImages = {
  disdecorLogo,
  disdecorTitle,
};

export default commonImages;
