import React from "react";
import { ZoomIn, ZoomOut } from "react-bootstrap-icons";

import { useControls } from "react-zoom-pan-pinch";

const Controls = (props: any) => {
  const { zoomIn, zoomOut, resetTransform } = useControls();

  return (
    <div className="tools d-flex flex-row gap-1">
      <button onClick={() => zoomIn()} className="btn btn-success">
        Zoom In <ZoomIn />
      </button>
      <button onClick={() => zoomOut()} className="btn btn-secondary">
        Zoom Out <ZoomOut />
      </button>
      <button onClick={() => resetTransform()} className="btn btn-danger">
        Reset
      </button>
    </div>
  );
};

export default Controls;
