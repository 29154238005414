import React from "react";
import { ChevronRight } from "react-bootstrap-icons";

import "./style.css";

function index() {
  return <ChevronRight size={30} id="arrow-right" />;
}

export default index;
