import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";

import About from "./pages/About";
import Landing from "./pages/Landing";
import Services from "./pages/Services";
import Clients from "./pages/Clients";
import CallToAction from "./pages/CallToAction";
import StickyNavbar from "./common/components/StickyNavbar";
import Footer from "./common/components/Footer";
import { ContextProvider } from "./context";

function App() {
  return (
    <ContextProvider>
      <div className="App gradient-background">
        <StickyNavbar />
        <Routes>
          <Route
            path="/"
            element={
              <div id="home-container">
                <Landing />
                <CallToAction id="call-to-action" />
              </div>
            }
          />
          <Route path="/About" element={<About />} />
          <Route path="/Clients" element={<Clients />} />
          <Route path="/Services" element={<Services />} />
        </Routes>
        <Footer />
      </div>
    </ContextProvider>
  );
}

export default App;
