import serviceImages from "../../utils/ImageLoaders/Services";

const serviceData = {
  services: [
    {
      title: "Floral",
      description:
        "We create beautiful floral arrangements that can be placed throughout your venue. This may include table centerpieces, floral installations, and other decorative arrangements.",
      images: serviceImages.floral,
    },
    {
      title: "Drapery",
      description:
        "Drapery can help transform the look and feel of a club, adding a touch of sophistication and elegance to the space. The right drapery can create a beautiful backdrop for events or simply enhance the overall ambiance.",
      images: serviceImages.drapery,
    },
    {
      title: "Custom Installations & Rentals",
      description:
        "Our custom installations help create unique and unforgettable experiences. We offer design consultations, concept development, fabrication, installation and set-up, maintenance, and removal.",
      images: serviceImages.custom,
    },
    {
      title: "Lighting",
      description:
        "We design and install custom lighting systems that highlight the key features of the venue and create the desired ambiance. Our high-quality lighting options help enhance the overall experience of your venue.",
      images: serviceImages.lighting,
    },
    {
      title: "Seasonal",
      description: "We design and install seasonal displays and rental units.",
      images: serviceImages.seasonal,
    },
  ],
};

export default serviceData;
